<template>
    <div class="bg-white-pure" :class="{'list-wrapper': !summary}">
        <div class="flex items-center pb-8 justify-between" v-if="clients && clients.length && !hideTitle">
            <div class="flex">
                <ClientsSvg class="text-primary-light w-10 mr-2" />
                <h1 class="text-black text-xl lg:text-2xl">Young People</h1>
            </div>
            <v-btn
                v-if="showAddClient"
                depressed
                class="v-btn--flat inline bg-green-light"
                color="success"
                value="submit"
                @click.prevent="confirmAddClient"
            >
                <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" />
                Add Client
            </v-btn>
        </div>
        <div class="a-client-list" v-if="clients">
            <div class="flex items-center mb-4 w-full">
                <div class="mr-2">
                    <fa-icon icon="filter" class="text-sm text-black" />
                </div>
                <v-checkbox
                    hide-details
                    class="mt-0 mr-4"
                    :ripple="false" 
                    label="Assigned to me"
                    v-model="assignedToMe"
                    @change="resetPage"
                ></v-checkbox>
            </div>
            <div class="flex flex-row">
                <div class="flex items-center w-full">
                    <!-- TODO: add in filter -->
                    <!--Logged in user only sees clients in the site-sections theyre assigned with -->
                    <ClientTable
                        ref='clientsTable'
                        :table-data="clients"
                        :summary="summary"
                        :page-size="pagination.limit"
                        :total="total"
                        class="client-list"
                        :loading="loading"
                        :assigned-to-me="assignedToMe"
                        @update-pagination="updatePagination"
                    />
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center h-20 w-full" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </div>
</template>

<script>
import ClientTable from './partials/ClientTable'
import Loader from '@/assets/loader.svg'
import camelcaseKeys from 'camelcase-keys'
import GET_CLIENTS from '@/graphql/queries/getClients.gql'
import ClientsSvg from '@/assets/icons/nav/icons_clients.svg'
import snakecaseKeys from 'snakecase-keys'
import {mapState} from 'vuex'
import {ClientHelpers} from '@/components/shared/mixins/clientMixins'
import {getInstance} from '@/auth'

const DEFAULT_ORDER_BY = {firstName: 'asc_nulls_first'}

export default {
    name: 'Clients',
    mixins: [ClientHelpers],
    components: {
        ClientTable,
        Loader,
        ClientsSvg
    },
    props: {
        pageSize: {
            type: Number,
            default: 25
        },
        hideTitle: {
            type: Boolean,
            default: false
        },
        summary: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Clients'
        },
        showAddClient: {
            type: Boolean,
            default: true
        },
        search: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loading: false,
            clients: [],
            total: 0,
            pagination: {
                offset: 0,
                limit: this.pageSize || 10,
            },
            orderBy: null,
            assignedToMe: false,
            skipFiltered: false,
        }
    },
    apollo: {
        clients: {
            query: GET_CLIENTS,
            fetchPolicy: 'network-only',
            variables() {
                return {
                    offset: this.pagination.offset,
                    limit: this.pagination.limit,
                    locationPrograms: this.loggedInUser.locationProgramIds,
                    userIdCompare: this.assignedToMe ? {_eq: this.loggedInUser.userId} : {},
                    orderBy: snakecaseKeys(this.orderBy || DEFAULT_ORDER_BY, {deep: true})
                }
            },

            skip() {
                return this.search
            },
            result({ data: {clients, total} }) {
                this.total = total.aggregate.count
                clients.forEach(client => {
                    const episode = client.episodes ? client.episodes[0] : null
                    if (episode) {
                        client.locationProgramId = episode.locationProgramId
                        client.status = episode.status
                    }
                })
                this.clients = camelcaseKeys(clients || [], { deep: true })
            },
            watchLoading (isLoading) {
                this.loading = isLoading
            },
        },
    },
    mounted() {
        const search = Number(this.search)
        this.skipFiltered = search != null && !isNaN(search) && search > 0
    },
    methods: {
        updatePagination({ page, itemsPerPage, sortBy, sortDesc }) {
            // console.log({ page, itemsPerPage, sortBy, sortDesc })
            if (itemsPerPage * (page - 1) > this.total) return

            this.pagination.limit = itemsPerPage
            this.pagination.offset = this.pagination.limit * (page - 1)
            
            let orderBy = {}
            if (sortBy && sortBy.length) {
                sortBy.forEach((fieldName, i) => {
                    const sortDir = sortDesc[i] ? 'desc_nulls_last' : 'asc_nulls_first'

                    switch (fieldName) {
                        case 'total':
                            orderBy['episodes_aggregate'] = { count: sortDir }
                            break
                        default:
                            orderBy[fieldName] = sortDir
                    }
                })
            }
            orderBy = snakecaseKeys(orderBy, { deep: true })

            if (JSON.stringify(orderBy) !== JSON.stringify(this.orderBy)) {
                if (Object.keys(orderBy).length) this.orderBy = orderBy
                else this.orderBy = snakecaseKeys( DEFAULT_ORDER_BY, { deep: true })
            }
        },
        resetPage() {
            this.pagination.offset = 0
            this.$refs.clientsTable.setPage(1)
        },
        confirmAddClient() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: 'test',
                keepOpen: true,
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalConfirmAddClient')
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser
        }),
    },
    watch: {
        pageSize() {
            this.pagination.limit = this.pageSize
        }
    },
}
</script>
